'use strict';

import Swiper from 'swiper/js/swiper.min.js';

class Slider {
  init() {
    window.addEventListener(
      'load',
      () => {
        this.slide();
      },
      false,
    );
  }

  slide() {
    const swiper = new Swiper('.swiper-container', {
      speed: 650,
      effect: "fade",
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      }
    });

    const stopBtn = document.querySelector('.pagination-stop-btn');
    stopBtn.addEventListener('click', () => {
      if (stopBtn.classList.contains('stop')) {
        stopBtn.classList.add('start');
        stopBtn.classList.remove('stop');
        swiper.autoplay.stop();
      } else {
        stopBtn.classList.add('stop');
        stopBtn.classList.remove('start');
        swiper.autoplay.start();
      }
    });
  }
}
const slider = new Slider();
slider.init();